<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Faq" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <div>
          <md-button class="md-primary mx-4" :to="{ name: 'Faq Category' }">
            View Categories
          </md-button>
          <md-button class="md-primary" :to="{ name: 'Faq Create' }">
            Create Faq
          </md-button>
        </div>
      </div>
      <md-table
        v-model="faq.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <!-- <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start"></div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-icon>search</md-icon>
              <md-input placeholder="Search" v-model="search" />
            </md-field>
          </div>
        </md-table-toolbar> -->
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N">{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Question">{{ item.question }}</md-table-cell>
          <md-table-cell md-label="Category">{{
            item.category.title
          }}</md-table-cell>

          <md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.status == 'live',
                grey: item.status == 'draft',
              }"
            >
              {{ item.status }}
            </div></md-table-cell
          >
          <md-table-cell md-label="Actions" md-numeric>
            <div class="table-actions">
              <md-button
                :disabled="updating"
                md-menu-trigger
                class="bggreen btn-sm"
                :to="{ name: 'Faq Edit', params: { id: item.id } }"
                >Edit</md-button
              >
              <md-button
                :disabled="updating"
                @click="remove(item.id)"
                class="bggreen md-icon-button md-raised btn-icon"
                ><i class="material-icons">delete</i></md-button
              >
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="faq.mdPage"
        :records="faq.mdCount"
        :per-page="faq.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("faq");
export default {
  name: "Faq",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      time: null,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getFaqs", "deleteFaq"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getFaqs(data);
    },
    async submitSearch() {
      await this.getFaqs({ keyword: this.search });
    },
    async exportExcel() {
      await this.$store.dispatch("auth/export", { model: "advert" });
    },

    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async remove(id) {
      try {
        const conf = confirm("Are you sure?");
        if (conf) {
          this.deleting = true;
          await this.deleteFaq(id);
          this.deleting = false;
          this.toast("success", "Faq Deleted");
        }
      } catch (err) {
        this.deleting = false;

        this.toast("error", "Opps, something went wrong");
      }
    },
  },
  created() {
    this.getFaqs();
  },
  mounted() {},
  computed: {
    ...mapGetters(["faq", "loading"]),
  },
};
</script>
<!-- jkfj -->

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.table-actions {
  width: 100%;
  display: flex;
  justify-content: end;
}
</style>
